<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">Attachments</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                
                <div class="pb-2">
                  <div class="container-fluid">
                    <div class="row m-0" v-if="attachments.length > 0">
                      <div class="col-md-3 col-sm-6 col-12 " v-for="(file, index) in attachments">
                        <div class="row files">
                          <div class="col-2">
                            <i class="fas fa-file-upload fa-2x"></i>
                          </div>
                          <div class="col-10 position-relative">
                            <a :href="file.file_path" target="_blank">
                              <h5 class="mb-0">
                                <span class="text-primary">{{ file.name }}</span>
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue';

const props  = defineProps({
  attachments: {
    type: Array,
    default: []
  },
});
const isOpen = ref(false);

function toggleModal () {
  isOpen.value = !isOpen.value
}

defineExpose({
  toggleModal
})
</script>
<style scoped>
.files{
  border: 1px solid #2D2D2D;
  background-color: #f2f2f2;
  padding: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  box-sizing: border-box;
  border-radius: 5px;
}
</style>