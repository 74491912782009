<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div style="background: #DBE7F2" class="p-2">
            <TitleButton
                btnTitle="Add New"
                :showBtn="false"
                :showAddNew="true"
                title="Contra Voucher List"
                @onClickAddNewButton="onClickAddNewButton"
            />
            <div class="row match-height mt-1">
                <div class="col-12 col-lg-8 col-xl-9 col-xxl-7">
                    <DateQuerySetter
                        @onClickGo="getJournals"
                    />
                </div>
            </div>
        </div>

        <ListTable />
        <div class="mb-2"></div>

        <div class="px-2 position-absolute bottom-0">
            <Pagination
                :offset="offset"
                @onPageChange="onPageChange"
                ref="pagination"
            />
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import handleJournal from '@/services/modules/journal'
import {computed, inject, onMounted, ref} from 'vue'
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/ngo/contra/ListTable'
import Pagination from '@/components/atom/Pagination'
import Loader from '@/components/atom/LoaderComponent'
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";

const $route = useRoute();
const $router = useRouter();
const $store = useStore();
const offset = ref(20);
const showError =  inject('showError');
const showSuccess =  inject('showSuccess');
const {fetchJournals,loading} = handleJournal()

//computed
const companyId = computed(() => $route.params.companyId);
const start = computed(() => $route.query.start);
const end = computed(() => $route.query.end);
const page = computed(() => $route.query.page);
const journals = computed(() => $store.state.journal.journals)

//methods
const setJournals = (data) => {
    $store.commit('journal/setJournals', data)
};

const setPaginationData = (data) => {
    $store.commit('setPaginationData', data)
};

const resetPagination = (data) => {
    $store.commit('resetPagination')
};

const onClickAddNewButton = () => {
    let params = {
        companyId: $route.params.companyId,
        moduleId: $route.params.moduleId,
        menuId: $route.params.menuId,
        pageId: $route.params.pageId,
    }
    $router.push({
        name: 'np-contra-voucher-create',
        params: params,
        query: $route.query
    })
};

const onPageChange = (page) => {
    let routeQuery = Object.assign({}, $route.query)
    routeQuery.page = page
    $router.push({path: $route.path, query: routeQuery})
    setTimeout(() => {
        getJournals()
    },100)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
};

const getQuery = () => {
    let query = '?company_id=' + companyId.value
    if(!start && !end) return query
    if(start) query += '&start_date='+start.value
    if(end) query += '&end_date='+end.value
    query += '&offset='+offset.value
    if(page) query += '&page='+page.value
    query += '&voucher_type=contra_voucher'
    return query
};

const getJournals = async () => {
    const query = getQuery()
    try {
        loading.value = true;
        let res = await fetchJournals(query)
        if(!res.status) {
            resetPagination()
        }
        if(res.status) {
            setJournals(res.data.data)
            if(res.data.data.length > 0) {
                setPagination(res.data)
            }
        }
    } catch (err) {
        if(!err.response) {
            showError('Something is wrong. Check your connectivity!!')
        }
        if(err.response) {
            showError(err.response.message)
        }
    } finally {
        loading.value = false
    }
}

onMounted( async () => {
    await getJournals()
})
</script>
